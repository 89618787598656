import React from 'react';
import { Framework } from '@components';
import { navigate } from 'gatsby'

const SectionB = () => {

  function redirectCreateAPI3StepsGoogleSheets() {
    navigate('/create-an-api-in-3-steps-using-google-sheets')
  }

  function redirectCreateMongo() {
    navigate('/create-an-api-in-3-steps-using-mongodb')
  }

  return (
    <section className="relative bg-background dark:bg-dark-charcoal p-4 overflow-hidden lg:pb-44 py-12 lg:pt-20 min-h-screen">
      <div className="container relative mx-auto z-20 pt-32 max-w-4xl">
        <div className=" w-full text-left">
          <div className="font-helvetica text-3xl lg:text-4xl font-bold dark:text-light-gray mb-8">
          Tutorials
          </div>
          {/* <div className="mt-4 mb-10 font-lato text-md text-dark-gray dark:text-gray lg:w-1/2">
          Building APIs becomes incredibly fast and effortless. Integrate and connect your front-end through REST, while enjoying the benefits of built-in connectors and easy querying for external data apps like Google Sheets, MongoDB, Microsoft SQL, and many others.
          </div> */}
        </div>

      <div class="py-4 video-container">
                        <iframe src="https://www.youtube.com/embed/LD3zNBClz4M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
          <div class="w-full hover:bg-sky-700" onClick={redirectCreateAPI3StepsGoogleSheets}>
            <Framework description="Spreadsheet" title="Create an API in 3 steps using Google Sheets"></Framework>
          </div>
          <div class="w-full hover:bg-sky-700" onClick={redirectCreateMongo}>
            <Framework description="NoSql Database" title="Create an API in 3 steps using MongoDb"></Framework>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionB;
