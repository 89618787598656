import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '@containers/layout/layout.container';
import SectionA from '@containers/section-a/section-a.container';
import SectionB from '@containers/section-b/section-b.container';
import SectionC from '@containers/section-c/section-c.container';
import SectionD from '@containers/section-d/section-d.container';
import SectionE from '@containers/section-e/section-e.container';

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Documentation</title>
        <link rel="canonical" href="https://docs.stackbase.io/" />
        <meta
          name="description"
          content="Learn how to build no-code APIs and back-end applications in the cloud using Stackbase."
        ></meta>
      </Helmet>
      {/* <SectionA></SectionA> */}
      <SectionB></SectionB>
      {/* <SectionC></SectionC>
      <SectionD></SectionD>
      <SectionE></SectionE> */}
    </Layout>
  );
};

export default IndexPage;
